$greyBackground: #F7F8FA;
$greyDark: #6C7A89;
$blueDark: #054885;
$blueLight: #7bbefa;
$orange: #f29652;
$blueNew: #5D9DF4;
// $blueLight:#D4E5FC;
$greyNew: #676062;
$grey2New: #A1A4A8;
$whiteNew: #FAFBFC;
$red_error:#fadcd9;
$red_error_color:#e33c2c;
$green_success:#cbf3dc;
$green_success_color:#2ab665;
$light_blue:#dbedf9;

.green{
	color:$green_success_color;
}