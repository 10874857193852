.horizontalForm{
	display:flex;
	flex-direction:rows;
	.horizontalFormPart{
		flex:1;
	}
}
.centeredForm{
	display:flex;
	flex-direction:rows;
	.centeredFormPart{
		flex:1;
	}
}
.formTitle{
	font-size:12px;
	color:$greyNew;
	// font-weight: bold;
	margin-bottom:15px;
	text-decoration: underline;
}

.mainTitle{
	font-weight:400;
	font-size:13px;
	margin-bottom:10px;
	letter-spacing: 0.6px;
	color:$greyNew;
	text-align: center;
}

form{
	a{
		color:$greyNew;
		text-decoration: underline;
		&:hover{
			color:$blueNew;
		}
	}
}

.formButton{
	text-align:center;
	button{
		padding-top:0px;
		padding-bottom:0px;
		height:25px;
		line-height:22px;
		padding-top:0px;
		padding-bottom:0px;
		padding-left:15px;
		padding-right:15px;
		border-radius:15px;
		font-size:12px;
		margin-left:10px;
		margin-right:10px;
		&.positiv{
			background:$blueNew;
			color:$whiteNew;
			border:1px solid $blueNew;
			&:hover{
				background:white;
				color:$blueNew;
				border-color:$blueNew;
			}
		}
		&.negativ{
			background:transparent;
			color:$grey2New;
			border:1px solid $grey2New;
			&:hover{
				background:$grey2New;
				color:$whiteNew;
			}
		}
	}
}

ul.stepForm{
	margin:0px;
	padding:0px;
	text-align:center;
	margin-top:20px;
	margin-bottom:30px;
	li{
		width:150px;
		list-style-type: none;
		display:inline-block;
		text-align:center;
		div{
			font-size:9px;
			text-transform: uppercase;
			letter-spacing: 1.2px;
			text-align: center;
		}
		.num{
			width:35px;
			height:35px;
			margin-left:auto;
			margin-right:auto;
			text-align:center;
			line-height:35px;
			font-size:15px;
			border-radius:50%;
			background:transparent;
			border:1px solid $greyNew;
			color:$greyNew;
			margin-bottom:8px;
			i{
				margin-top:10px;
			}
		}
		&:hover,&.active{
			color:$blueNew;
			.num{
				background:$blueNew;
				color:$whiteNew;
				border-color:$blueNew;
			}
		}
	}
}