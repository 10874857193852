.modal-overlay{
	position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  background-color: rgba(0,0,0,0.6);
  overflow: scroll;
}
.modal{
	flex-direction:column;
	max-width:1000px;
	background:white;
	margin-left:auto;
	margin-right:auto;
	padding:30px;
  // min-width:300px;
	margin-top:50px;
	border-radius:4px;
	position:relative;
	&.modalInspectionBan, &.modalApikey{
		width:300px;
	}
	&.modalEditUserInfos{
		width: 300px;
		padding:20px;
		.mainTitle{
			margin-bottom:10px;
		}
		.formInput{
			margin-bottom:8px;
		}
		.formButton{
			margin-top:10px;
		}
	}
	// position: absolute;
 //    top: 40px;
 //    left: 40px;
 //    right: 40px;
 //    bottom: 40px;
	// background:rgba(0,0,0,0.6);
	.modal-blue-bg{
		// background:rgba(123,190,250,0.2);	
	}

	.modalContent{
		&.borderbottom{
			border-bottom:1px solid rgba(0,0,0,0.15);
		}
		button.close{
			position:absolute;
			z-index:2147483647;
			top:10px;
			right:8px;
			background:transparent;
			color:$greyDark;
			border:none;
			cursor:pointer;
			font-size:15px;
			&:hover{
				color:$blueDark;
			}
		}
	}
}