$btnHeightType1: 40px;
button{
	font-size:13px;
	border-radius:4px;
	cursor:pointer;
	&.type1{
		padding-top:0px;
		padding-bottom:0px;
		height:$btnHeightType1;
		line-height: $btnHeightType1;
		padding-left:30px;
		padding-right:30px;
		color:$greyDark;
		background:white;
		// border:1px solid $blueDark;
		font-size:13px;
		border:1px solid rgba(0,0,0,0.08);
		border-bottom:2px solid rgba(0,0,0,0.15);
		&:hover{
			// background:$blueDark;
			color:$blueDark;
			border-color:$blueDark;
		}
	}

	&.type2{

	}

	&.buttonInList{
		cursor:pointer;
		background:transparent;
		color:$greyDark;
		border:none;
		font-size:12px;
		padding-left:0px;
		margin-right:20px;
		&:hover{
			color:$orange;
		}
	}
	&.buttonInListBlue{
		@extend .buttonInList;
		// color:rgba(123,190,250,0.2);
		color:white;
	}

	i{
		margin-right:5px;
	}

	&.actionButton{
		background:none;
		color:$greyNew;
		border:none;
		font-size:12px;
		padding:0px;
		margin-right: 25px;
		&:hover{
			color: $blueNew;
		}
	}

}