@media only screen and (max-width : 383px) {
  /* Styles pour cette Media Queries */
  .viewer__full, .viewer__photos, .viewer__synthesis {
    top: 8rem;
  }
  .image-doc {
    max-width: 70vw;
  }
}

@media (min-width: 384px) and (max-width: 760px){
  /* Styles pour cette Media Queries */
  .viewer__full, .viewer__photos, .viewer__synthesis {
    top: 9rem !important;
  }

  .image-doc {
    max-width: 25vw;
    max-height: 15rem;
  }
}

@media only screen and (min-width: 765px) and (max-width: 960px){
  /* Styles pour cette Media Queries */
  .viewer__full, .viewer__photos, .viewer__synthesis {
    top: 9rem;
  }

  .image-doc {
    max-width: 50vw;
  }
}

@media only screen and (min-width: 961px) and (max-width: 992px){
  /* Styles pour cette Media Queries */
  .viewer__full, .viewer__photos, .viewer__synthesis {
    top: 9rem;
  }
}

@media only screen and (min-width: 993px) and (max-width : 1442px){
  /* Styles pour cette Media Queries */
  .viewer__full, .viewer__photos, .viewer__synthesis {
    top: 9rem;
  }

  .viewer__synthesis__left {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }

  .viewer__synthesis__right {
    padding-left: 15px !important;
    padding-right: 5px !important;
  }

  .viewer .full__block:first-child {
    padding-right: 0 !important;
  }
}
