$break-xsmall: 320px;
$break-small: 480px;
$break-xmedium: 785px;
$break-medium: 960px;
$break-large: 1200px;

.image-container {
  .anomalyDot {
    width: 10px;
    height: 10px;
    border: solid 1px white;
    position: absolute;
    z-index: 17;
    cursor: pointer;
    border-radius: 10px;
    background: orange;
    padding: 0 !important;

    &__medium {
      width: 5px;
      height: 5px;

      @media screen and (max-width: $break-xmedium) {
        width: 10px;
        height: 10px;
      }
    }

    &__small {
      width: 3px;
      height: 3px;

      @media screen and (max-width: $break-xmedium) {
        width: 10px;
        height: 10px;
      }
    }
  }

  .anomalyContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: auto;
    border: solid 1px #dfdfdf;
    background: white;
    z-index: 18;

    @media screen and (max-width: $break-small) {
      left: 40px !important;
    }

    cursor: default;
    padding: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);

    &.fre {
      left: 0;
      top: 20px;
      position: absolute;
      padding: 5px;
    }

    &.fre-infos {
      position: absolute;
      font-size: 1rem;
      display: block;
      right: 0;
      border: solid 1px #dfdfdf;
      background: white;
      z-index: 11;
      min-width: 350px;
      padding: 10px 20px;
      text-align: justify;
      max-height: 500px;
      overflow-y: auto;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    }

    .title {
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
    }

    .image {
      padding: 10px;

      &:hover {
        cursor: zoom-in;
      }
    }

    .desc {
      border-top: solid 1px #e1e1e1;
      background: #f8f8f8;
      width: 100%;
      text-align: center;
    }
  }
}

.hover {
  padding: 0px !important;
  // div {
  //   padding: 0 !important;
  // }
  position: relative;

  &:hover &__no-hover {
    opacity: 0;
  }

  &:hover &__no-hide {
    opacity: 1;
  }

  &:hover &__hover {
    opacity: 1;
    display: inherit;
  }

  &__hover {
    display: none;
  }

  &__no-hover {
    padding: 0;
    opacity: 1;
  }
}

.photoSectionPadding {
  padding-left: 1.4rem !important;
  padding-right: 1.4rem !important;
}
