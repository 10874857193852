.notfound{
	width:300px;
	margin-top:100px;
	margin-left:auto;
	margin-right:auto;
	background:white;
	text-align:center;
	border-radius:5px;
	@extend .boxshadow;
	padding:25px;
	font-size:13px;
	img{
		width:100px;
		height:auto;
		margin-bottom:10px;
	}
	h1{
		color:$greyNew;
	}
	a{
		color:$blueNew;
		text-decoration: none;
		&:hover{
			color:$blueNew;
		}
	}
}