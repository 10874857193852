$navbar-height: 50px;
// $greyBackground
.navbar_item {
  display: flex;
  flex-direction: row;
  height: $navbar-height;
  background: $whiteNew;
  flex-direction: row;
  line-height: $navbar-height;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 999999999;
  .logo {
    width: 280px;
    a {
      @extend .lato;
      font-weight: normal;
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      text-decoration: none;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $greyDark;
      &:hover {
        cursor: pointer;
      }
      span {
      }
    }
  }
  .navbar_contact {
    // width:190px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    @extend .lato;
    color: $blueNew;
  }
  .navbar_actions {
    flex: 1;
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    // align-items: flex-start;
    a {
      @extend .lato;
      cursor: pointer;
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: 5px;
      // color:$blueDark;
      // color: $greyDark;
      color: $greyNew;
      text-decoration: none;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 2px;
      &:hover,
      &.active {
        cursor: pointer;
        // color:$blueDark;
        color: $blueNew;
        // border-bottom:2px solid $blueDark;
        border-bottom: 2px solid $blueNew;
      }
    }
    .space {
      flex: 1;
    }
    button {
      &.link {
        @extend .lato;
        cursor: pointer;
        margin-left: 30px;
        margin-right: 30px;
        padding-bottom: 5px;
        border: none;
        border-radius: 0px;
        padding-left: 0px;
        padding-right: 0px;
        // color:$blueDark;
        // color: $greyDark;
        color: $greyNew;
        text-decoration: none;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
        &:hover,
        &.active {
          cursor: pointer;
          // color:$blueDark;
          color: $blueNew;
          // border-bottom:2px solid $blueDark;
          border-bottom: 2px solid $blueNew;
        }
      }
      &.button {
        margin-left: 30px;
        margin-right: 30px;
        background: transparent;
        border: 1px solid $grey2New;
        color: $grey2New;
        font-size: 12px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 15px;
        height: 27px;
        line-height: 24px;
        padding-right: 15px;
        border-radius: 15px;
        cursor: pointer;
        &:hover {
          background: $blueNew;
          color: white;
          border-color: $blueNew;
        }
      }
      &.notifications {
        height: 50px;
        width: 30px;
        border: none;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        padding: 0px;
        // margin: 0px;
        // display: flex;
        flex-direction: row;
        align-items: center;
        i {
          font-size: 23px;
          color: $grey2New;
          padding: 0px;
        }
        > div {
          position: absolute;
          bottom: 6px;
          right: 0px;
          background: #f03434;
          padding: 2px 5px;
          border-radius: 4px;
          color: white;
          font-size: 9px;
        }
        .notificationsContainer {
          position: absolute;
          top: 55px;
          right: 0px;
          width: 350px;
          height: 200px;
          z-index: 99999999;
          background: white;
          color: $grey2New;
          display: flex;
          flex-direction: column;
          @extend .boxshadow;
          .notificationsContainerTitle {
            font-size: 10px;
            padding-top: 4px;
            padding-bottom: 4px;
            color: #f03434;
            padding-left: 5px;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 10px;
          }
          ul {
            flex: 1;
            padding: 0px;
            margin: 0px;
            overflow-y: scroll;
            li {
              text-align: left;
              margin-bottom: 15px;
              .date {
                font-size: 11px;
                padding-right: 5px;
                margin-bottom: 4px;
                text-align: right;
              }
              .contentContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                div.button {
                  padding-left: 5px;
                  padding-right: 5px;
                  background: transparent;
                  border: none;
                  font-size: 12px;
                  cursor: pointer;
                  z-index: 99999999;
                  i {
                    font-size: 14px;
                    color: $grey2New;
                  }
                  &:hover {
                    i {
                      color: #26c281;
                    }
                  }
                }
                .content {
                  flex: 1;
                  font-size: 12px;
                  color: #434343;
                }
              }
            }
          }
        }
      }
      &.rappels {
        height: 50px;
        width: 30px;
        border: none;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        padding: 0px;
        // margin: 0px;
        // display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        i {
          font-size: 23px;
          color: $grey2New;
          padding: 0px;
        }
        > div {
          position: absolute;
          bottom: 6px;
          right: 0px;
          background: #f03434;
          padding: 2px 5px;
          border-radius: 4px;
          color: white;
          font-size: 9px;
        }
        .rappelsContainer {
          position: absolute;
          top: 55px;
          right: 0px;
          width: 350px;
          height: 200px;
          z-index: 99999999;
          background: white;
          color: $grey2New;
          display: flex;
          flex-direction: column;
          @extend .boxshadow;
          .rappelsContainerTitle {
            font-size: 10px;
            padding-top: 4px;
            padding-bottom: 4px;
            color: #f03434;
            padding-left: 5px;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 10px;
          }
          ul {
            flex: 1;
            padding: 0px;
            margin: 0px;
            overflow-y: scroll;
            li {
              text-align: left;
              margin-bottom: 15px;
              .date {
                font-size: 11px;
                padding-right: 5px;
                margin-bottom: 4px;
                text-align: right;
              }
              .contentContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                div.button {
                  padding-left: 5px;
                  padding-right: 5px;
                  background: transparent;
                  border: none;
                  font-size: 12px;
                  cursor: pointer;
                  z-index: 99999999;
                  i {
                    font-size: 14px;
                    color: $grey2New;
                  }
                  &:hover {
                    i {
                      color: #26c281;
                    }
                  }
                }
                .content {
                  flex: 1;
                  font-size: 12px;
                  color: #434343;
                }
              }
            }
          }
        }
      }
    }
    .navbar_contact {
      display: inline-block;
    }
  }
}

.test {
  margin-top: 2;
  background: white;
}
