$break-xsmall: 320px;
$break-small: 480px;
$break-xmedium: 785px;
$break-medium: 960px;
$break-large: 1200px;

p { margin: 0; }
a { text-decoration: none; }
.blueBold {
  font-family: Avenir Next LT Pro Regular;
  font-size: 14px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: inherit;

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
  // margin: 0;
}
// .warmGrey {

// }
// .headerName {
//   font-size
// }

.blueTitle {
  font-family: Avenir Next LT Pro Regular;
  font-size: 20px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #1f5288;

  @media screen and (max-width: $break-large) {
    font-size: 18px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 20px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 14px;
  }
}

.darkLabel {
  font-family: Avenir Next LT Pro Regular;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
}

.greyLabel {
  font-family: Avenir Next LT Pro Regular, serif;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #9b9b9b !important;

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
}
.weight200 {
  font-weight: 200;
}
.weight500 {
  font-weight: 500;
}
.obliqueBlue {
  color: #1e5288;
  font-style: oblique;
  font-family: Avenir Next LT Pro Regular;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
}
.blackValue {
  font-family: Avenir Next LT Pro Regular;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4a4a4a;

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
}
.priceValue {
  font-family: Avenir Next LT Pro Regular;
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #f4a621;
  margin-top: 5px;
  padding-right: 10px;
  display: flex;

  .valuePrice {
    min-width: 60px;
    font-weight: 400;
  }

  .labelPrice {
    min-width: 60px;
    text-align: left;
  }

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
}
// .totalLabel {
//   font-family: Avenir Next LT Pro Regular;
//   font-size: 14px;
//   font-weight: 900;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.43;
//   letter-spacing: normal;
//   color: blue;
// }
.priceTotal {
  font-family: Avenir Next LT Pro Regular;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #1e5187;

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-medium) {
    font-size: 14px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 12px;
  }
}
.condensed {
  font-family: Avenir Next LT Pro Demi Condensed;
}
.regular {
  font-family: Avenir Next LT Pro Regular;
  font-size: 14px;

  @media screen and (max-width: $break-large) {
    font-size: 12px;
  }

  @media screen and (max-width: $break-small) {
    font-size: 10px;
  }
}
.center {
  text-align: center;
}
.green {
  color: green;
}
.red {
  color: red;
}
.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font20 {
  font-size: 20px;
}
.font22 {
  font-size: 22px;
}

.textRight { text-align: right; }

.textLeft { text-align: left; }

.textCenter { text-align: center; }

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Regular'), url('../fonts/AvenirNextLTPro-Regular.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Condensed'), url('../fonts/AvenirNextLTPro-Cn.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Condensed Italic'), url('../fonts/AvenirNextLTPro-CnIt.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Italic'), url('../fonts/AvenirNextLTPro-It.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Ultra Light Condensed'), url('../fonts/AvenirNextLTPro-UltLtCn.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Ultra Light Condensed Italic'), url('../fonts/AvenirNextLTPro-UltLtCnIt.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Medium Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Medium Condensed'), url('../fonts/AvenirNextLTPro-MediumCn.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Medium Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Medium Condensed Italic'), url('../fonts/AvenirNextLTPro-MediumCnIt.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Demi';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi'), url('../fonts/AvenirNextLTPro-Demi.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Demi Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi Condensed'), url('../fonts/AvenirNextLTPro-DemiCn.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Demi Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi Condensed Italic'), url('../fonts/AvenirNextLTPro-DemiCnIt.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Demi Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Demi Italic'), url('../fonts/AvenirNextLTPro-DemiIt.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Bold'), url('../fonts/AvenirNextLTPro-Bold.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Bold Condensed'), url('../fonts/AvenirNextLTPro-BoldCn.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Bold Condensed Italic'), url('../fonts/AvenirNextLTPro-BoldCnIt.woff') format('woff');
  }


  @font-face {
  font-family: 'Avenir Next LT Pro Heavy Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Heavy Condensed'), url('../fonts/AvenirNextLTPro-HeavyCn.woff') format('woff');
  }


@font-face {
  font-family: 'Avenir Next LT Pro Heavy Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Next LT Pro Heavy Condensed Italic'), url('../fonts/AvenirNextLTPro-HeavyCnIt.woff') format('woff');
}
