$normalInputHeight: 30px;

.formInput{
	margin-bottom:20px;
	font-size:13px;
	&.center{
		text-align:center;
	}
	label{
		font-size:9px;
		text-transform: uppercase;
		letter-spacing:1.2px;
		// font-weight: bold;
		color:$greyNew;
		padding-left:4px;
		margin-bottom:3px;
		display:block;
	}	
	input[type="email"],input[type="text"],input[type="password"],input[type="number"],input[type="phone"],input[type="date"]{
		background:transparent;
		padding-left:4px;
		border-radius:4px;
		width:100%;
		max-width:300px;
		font-size:12px;
		color:black;
		height:$normalInputHeight;
		border:1px solid rgba(0,0,0,0.1);
	}
	input[type="checkbox"]{
		margin-right:5px;
		font-size:12px;
	}
	input[type="radio"]{
		margin-right:5px;
	}
	.radioValueText{
		margin-right: 12px;
		font-size:12px;
	}
	textarea{
		width:100%;
		max-width:300px;
		border:1px solid rgba(0,0,0,0.1);
		border-radius:4px;
		padding:4px;
		font-size:12px;
	}
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0,0,0,0.4);
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0,0,0,0.4);
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0,0,0,0.4);
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(0,0,0,0.4);
}