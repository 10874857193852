$break-xsmall: 320px;
$break-small: 480px;
$break-xmedium: 785px;
$break-medium: 960px;
$break-large: 1200px;

.filePdf {
  display: none;
  .header__nav__id {
    flex-flow: row !important;
    justify-content: space-between !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    div {
      flex: initial !important;
      width: auto !important;
    }
    img {
      width: 80%;
    }
  }
}

.header {
  position: fixed;
  z-index: 5;
  width: 100%;

  &.bodyIndex {
    z-index: 3;
  }

  &__nav {
    &__id {
      @media screen and (max-width: $break-large) {
        justify-content: space-around;
      }

      &__logo img {
        max-height: 80px;
        width: 100%;
      }
    }

    &__title {
      text-align: center;
      border: 1px solid #dee1e7;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 17px;

      @media screen and (max-width: $break-large) {
        padding: 16px 13px;
        min-width: 80px;
      }
    }

    &__translate {
      @media screen and (max-width: $break-large) {
        min-width: 200px;
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
    }

    &__links {
      @media screen and (max-width: $break-large) {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }
}

.nav {
  &__list {
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  &__item {
    text-align: center;
  }

  &__link {
    font-family: Avenir Next LT Pro Regular, serif;
    text-decoration: none;
    background-color: #9d9fa2;
    padding: 10px;
    border-radius: 10px;
    color: white;
    min-width: 80px;
    height: 80px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;

    &:hover {
      color: white;
    }

    &--active {
      background-color: #004a7e;
      box-shadow: 6px 6px 9px 0 rgba(0, 0, 0, 0.75);
    }
  }
}

.viewer {
  .synthesis {
    &__left,
    &__right {
      text-align: right;
    }

    .left,
    .right {
      &__block {
        .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        &--no-background {
          background: none;
        }
      }
    }

    .mr-2 {
      margin-right: 2rem;
    }
  }

  .full {
    &__block {
      display: flex;
      align-items: stretch;

      .block {
        flex-grow: 1;
      }
    }
  }

  &__full,
  &__photos,
  &__synthesis {
    position: relative;
    z-index: 4;
    top: 9rem;
    margin-bottom: 30px;
  }

  .content.picture.fixed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 15;
    justify-content: center;

    .pictureContainer {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      .overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
      }

      .navButton {
        border: 1px solid white;
        border-radius: 2px;
        position: absolute;
        z-index: 17;
        top: 0;
        bottom: 0;
        width: 20px;
        height: 34px;
        padding: 20px 15px;
        margin: auto 3px;
        cursor: pointer;
        opacity: 0.7;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.8);

        &:hover {
          background-color: #777;
          -webkit-transition: background-color 100ms linear;
          -ms-transition: background-color 100ms linear;
          transition: background-color 100ms linear;
        }

        &__prev {
          left: 0;
        }

        &__next {
          right: 0;
        }
      }

      .image-container {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 20px auto;
        height: auto;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &__img {
          max-width: 92vw;
          max-height: 92vh;
          min-width: 100px;
          min-height: 100px;
          transition: all 0.1s;
          cursor: pointer;
          background-size: 50px;
          transform-origin: center center;

          @media screen and (max-width: $break-xmedium) {
            margin: auto;
          }
        }
      }
    }
  }
}

.viewAnomalyPdf {
  display: flex;
  flex-direction: column;
  width: 100%;

  .viewAnomalyPdf-picture {
    width: 8rem;
    margin-bottom: 5px;
  }
}

.block {
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  background-color: white;

  &__content {
    padding: 10px 0;
  }

  &__title {
    font-size: 14px;
  }

  &__text {
    font-size: 12px;
    padding: 5px;
  }

  &__image {
    &--no-padding {
      padding: 0;
    }
  }
}

.section {
  &__left,
  &__right {
    .label {
      display: flex;
      justify-content: flex-end;
      line-height: 2em;
    }

    .value {
      padding-left: 5px !important;
      line-height: 2em;
    }
  }
}

.viewer-photos {
  img {
    cursor: zoom-in;
  }

  &__first {
    margin-bottom: 0.75rem;

    @media screen and (max-width: $break-xmedium) {
      margin-bottom: 0;
    }
  }

  &__big {
    .content.picture {
      position: relative;

      .icon-zoom {
        display: block;
      }
    }

    .hover:hover {
      .hover__hover {
        @media screen and (max-width: $break-small) {
          display: none;
        }
      }

      .hover__no-hover {
        @media screen and (max-width: $break-small) {
          opacity: 1;
        }
      }
    }
  }

  &__medium {
    @media screen and (max-width: $break-xmedium) {
      margin: 10px 0;
    }

    .content.picture {
      position: relative;

      .icon-zoom {
        display: none;

        &--fixed {
          display: block;
        }
      }

      &:hover {
        .icon-zoom {
          display: block;
        }
      }

      .hover:hover .hover__hover {
        opacity: 1;
        display: none;

        @media screen and (max-width: $break-xmedium) {
          display: inherit;
        }

        @media screen and (max-width: $break-small) {
          display: none;
        }
      }
    }
  }

  &__small {
    padding: 0 4px;

    @media screen and (max-width: $break-xmedium) {
      padding: 0 6px;
      margin: 10px 0;
    }

    .content.picture {
      opacity: 0.7;

      @media screen and (max-width: $break-xmedium) {
        opacity: 1;
      }

      &.fixed {
        opacity: 1;
      }
    }

    .icon-zoom {
      display: none;
    }

    .hover:hover .hover__hover {
      opacity: 1;
      display: none;

      @media screen and (max-width: $break-xmedium) {
        display: inherit;
      }

      @media screen and (max-width: $break-small) {
        display: none;
      }
    }
  }
}

.repair-cost {
  &__panel {
    &__header {
      margin-bottom: 20px;

      &__title {
        background-color: #1c598d;
        color: white;
        padding: 7px;
        font-family: Avenir Next LT Pro Regular, serif;
        font-size: 14px;

        span {
          margin-left: 20px;
        }
      }
    }

    &__content {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
          flex: none;
          width: 35%;
          text-align: right;
          padding: 10px;
          // padding-top: 4px;
        }

        .value {
          text-align: left;
          flex: unset;
          margin-right: 25px;
        }

        .dot {
          flex: 1;
          border-bottom: 1px dotted #d2d3d2;
          height: 3px;
        }

        .price {
          flex: none;
          text-align: right;
          padding: 4px 8px 8px 0;
        }
      }
    }
  }
}

.collapse-content {
  overflow: hidden;
  // note that we're transitioning flex, not height!
  transition: max-height 0.3s ease-out;
  max-height: 0;
}

.collapse-content.collapsed {
  overflow: visible;
  max-height: 100%;
  transition: max-height 0.3s ease-in;

  @media screen and (max-width: $break-xmedium) {
    max-height: 100%;
  }
}

.image-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 20;
  justify-content: center;

  &__container {
    height: 100%;
    width: 100%;
    display: flex;

    &__overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 21;
      background-color: rgba(0, 0, 0, 0.8);
    }

    &__picture {
      display: inline-block;
      z-index: 22;
      position: relative;
      margin: 20px auto;
      height: calc(100% - 40px);
    }

    &__icon {
      z-index: 22;
    }
  }
}

.hamburger {
  cursor: pointer;
  width: 20px;
}

.side-menu {
  top: 0;
  display: flex;
  flex-direction: column;
  margin-right: 74px;
  width: 100%;
  max-width: 320px;
  height: 100%;

  &.drawer {
    top: 92px;
    background: white;
    height: 100%;

    @media screen and (max-width: $break-xmedium) {
      display: flex;
    }
  }

  @media screen and (max-width: $break-xmedium) {
    display: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 35px;
    margin-bottom: 3px;
    color: #4a4a4a;
    border-radius: 0 25px 25px 0;

    svg {
      display: none;
    }

    &.active {
      svg {
        display: inherit;
      }

      color: white;

      &:hover {
        background-color: #1f5288;
      }

      background-color: #1f5288;
      cursor: pointer;
      box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.15);
    }

    &:hover {
      background-color: #538dcb0f;
      cursor: pointer;
    }
  }
}

.svg-download {
  margin-right: 5px;

  @media screen and (max-width: $break-large) {
    margin: 0;
  }
}

.button {
  &--small {
    @media screen and (max-width: $break-large) {
      max-width: 100px;
    }
  }

  &__download {
    text-decoration: none;
    font-family: Avenir Next LT Pro Regular, serif;
    color: #000000;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    &:hover {
      color: black;
    }
    @media screen and (max-width: $break-large) {
      max-width: 50px;
      justify-content: center;
    }

    &--yellow {
      background-color: #ec973e;
      color: #ffffff;
      border-radius: 22px;
      padding: 10px 15px;

      &:hover {
        color: white;
      }
    }
  }
}

.button-collapse {
  height: 25px;
  width: 35px;
  background-color: #a7a9ac;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 0;

  &--blue {
    background-color: #1c598d;
  }
}

.icon-zoom {
  padding: 6px;
  z-index: 3;
  position: absolute;
  top: 0;
  background-color: #185287;
  color: white;
  right: 0;
  text-align: center;

  &:hover {
    cursor: zoom-in;
  }

  .fa-search-plus {
    font-size: 15px;
  }
}

.header-icon {
  z-index: 16;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  right: 10px;
  top: 0;
  width: 2.5rem;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  box-sizing: initial;

  &:hover {
    cursor: pointer;
  }

  .icon-close {
    padding: 6px;
    color: white;
    text-align: center;

    .fa-times {
      font-size: 30px;
    }
  }
}

.cross-close {
  height: 25px;
  padding-top: 10px;
  padding-right: 0;
  opacity: 0.7;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.arrow-down {
  transition: transform 0.3s ease;
  transform: rotate(-90deg);
  cursor: pointer;

  &.collapsed {
    transform: rotate(0deg);
  }
}

.arrow-right {
  transform: rotate(-90deg);
  position: absolute;
  left: 7px;
}

.arrow-left {
  transform: rotate(90deg);
  position: absolute;
  left: 7px;
}

.anomalyCarousel {
  width: 300px;
  height: auto;
  position: relative;

  &.no-thumbs {
    .carousel {
      .control-dots {
        display: none;
      }
    }

    .carousel:nth-child(2) {
      display: none;
    }
  }
  &.carouselClearStatus {
    .carousel-status {
      display: none;
    }
  }
}

#labourTimeInfos {
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
}

#labourTimeInfos td,
#labourTimeInfos th {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;

  &.price {
    min-width: 60px;
  }
}

#labourTimeInfos tr:nth-child(even) {
  background-color: #f2f2f2;
}

#labourTimeInfos tr:hover {
  background-color: #ddd;
}

#labourTimeInfos th {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #1c598d;
  color: white;
}

.hideIfLarge {
  @media screen and (max-width: $break-large) {
    display: none;
  }
}

.hideIfMedium {
  padding-right: 0 !important;
  @media screen and (max-width: $break-medium) {
    display: none;
  }
}

.showIfMedium {
  @media screen and (min-width: $break-medium) {
    display: none;
  }

  @media screen and (max-width: $break-medium) {
    display: block;
  }
}

.hideIfXMedium {
  @media screen and (max-width: $break-xmedium) {
    display: none;
  }
}

.showIfXMedium {
  @media screen and (min-width: $break-xmedium) {
    display: none;
  }

  @media screen and (max-width: $break-xmedium) {
    display: block;
  }
}

/* Design Table */
.ant-table-content {
  font-size: 12px;

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 7px;
    font-size: 12px;
  }

  .ant-table-thead > tr > th {
    background-color: #256dab;
    color: white;
  }
}
.classForPiece {
  width: 70% !important;
}

.blocDetails {
  position: relative;
  font-size: 1rem;
  text-align: left;

  div {
    padding: 0 0 4px 2px;
    word-wrap: break-word;         /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;     /* Renamed property in CSS3 draft spec */
    width: 100%;

    span:first-child {
      font-weight: bold;
    }

    span:last-child {
      color: #1c598d;
    }
  }

  .damageTypeAndSeverity {
    display: flex;
    flex-direction: row;
    align-content: space-between;

    div {
      padding-right: 5px;
    }
  }
}

.bg-edit-cell {
  background-color: rgba(187, 222, 250, 0.72);
}

.editable-cell-value-wrap {
  padding-right: 0 !important;
}

.damages .obliqueLine {
  height: 3rem;
  width: 30px;
  border: none;
  margin-top: 8px;
  background: linear-gradient(-45deg, #ffffff 48%, #3c3b3b 50%, #ffffff 51%);
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-table-pagination {
  display: none;
}

.anomalyCarousel > img {
  width: 100%;
}

.anomalyLightbox > .ril__navButtons {
  display: none;
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.75);
}

.react-multiple-carousel__arrow {
  min-width: 22px;
  min-height: 22px;
  position: absolute;
  margin-top: 10% !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 12px;
}

.react-multiple-carousel__arrow--left {
  position: absolute;
  // top: calc(5% + 1px) !important;;
  // margin-top: 50%;
}

.goog-te-gadget .goog-te-combo {
  color: black;
}

.textTable {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  text-align: center;
  overflow-wrap: normal;

  &.left {
    text-align: left;
  }

  &.lowercase {
    text-transform: lowercase !important;
  }
}

.ant-picker-now {
  display: none !important;
}

.viewer__synthesis,
.synthesis {
  div div {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .margin-2 {
      margin-right: 2rem !important;
    }
  }
}

.viewer__synthesis__left,
.viewer__synthesis__right {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.repair-cost__panel__header__arrow {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.clearMargin {
  padding-left: 0 !important;
  padding-right: 0 !important;

  div > div:first-child {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.clearMargin.right {
  padding-left: 8px !important;
  padding-right: 0 !important;
}

.viewer .full__block:first-child {
  padding-right: 0 !important;
}

.spacePadding {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important;
}

.spacePaddingXs {
  padding-left: 3.75px !important;
  padding-right: 3.75px !important;
}

@media screen and (max-width: $break-large) {
  .viewer__synthesis {
    top: 8.5rem !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1003px) {
  .viewer__synthesis {
    top: 9.5rem !important;
  }
}

@media only screen and (min-width: 960px) and (max-width: 991px) {
  .viewer__synthesis {
    top: 12.5rem !important;
  }
}

.dropdown {
  // width: 300px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0,0,0,.1);
  background-color: white;
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #E5E8EC;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91A5BE;
  transition: all .2s ease-in-out;
  display: none;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91A5BE;
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

.menu {
  margin-top: 1rem;
  position: relative;
  width: 30px;
  height: 30px;

  .open {
    width: 80px;
  }
}

.tableCreate .ant-table-wrapper {
  margin-top: .5rem;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 10px;
}

.itemP {
  background: #aacbff;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 1rem;
  padding: 5px;
  position: relative;
  text-align: center;
  transition: background-color 0.05s ease-in-out;

  &:hover {
    background-color: #8cb8ff;
    color: white;
  }
}

.open {
  position: absolute;
  top: 100%;
  width: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  z-index: 12;
}

