$break-xsmall: 320px;
$break-small: 480px;
$break-xmedium: 785px;
$break-medium: 960px;
$break-large: 1200px;
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-offset-0,
.col-lg-offset-1,
.col-lg-offset-2,
.col-lg-offset-3,
.col-lg-offset-4,
.col-lg-offset-5,
.col-lg-offset-6,
.col-lg-offset-7,
.col-lg-offset-8,
.col-lg-offset-9,
.col-lg-offset-10,
.col-lg-offset-11,
.col-lg-offset-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-offset-0,
.col-md-offset-1,
.col-md-offset-2,
.col-md-offset-3,
.col-md-offset-4,
.col-md-offset-5,
.col-md-offset-6,
.col-md-offset-7,
.col-md-offset-8,
.col-md-offset-9,
.col-md-offset-10,
.col-md-offset-11,
.col-md-offset-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-offset-0,
.col-sm-offset-1,
.col-sm-offset-2,
.col-sm-offset-3,
.col-sm-offset-4,
.col-sm-offset-5,
.col-sm-offset-6,
.col-sm-offset-7,
.col-sm-offset-8,
.col-sm-offset-9,
.col-sm-offset-10,
.col-sm-offset-11,
.col-sm-offset-12,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-offset-0,
.col-xl-offset-1,
.col-xl-offset-2,
.col-xl-offset-3,
.col-xl-offset-4,
.col-xl-offset-5,
.col-xl-offset-6,
.col-xl-offset-7,
.col-xl-offset-8,
.col-xl-offset-9,
.col-xl-offset-10,
.col-xl-offset-11,
.col-xl-offset-12,
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.between-xs {
  justify-content: space-between !important;
  flex-flow: row !important;
}

.middle-xs {
  align-items: center !important;
}

.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
}

@page {
  border-right-color: transparent;
  background: #dee1e7;

  .image-doc {
    max-width: 25vw;
  }
}
html,
body {
  padding: 0;
  margin: 0;
  background: #dee1e7;
  font-size: 0.8em;
  top: 0 !important;
  border-right-color: transparent;
  width: 100%;
}

* {
  outline: none;
  font-family: 'Lato', sans-serif;
}

#app {
  z-index: 2;
  position: relative;
}

.skiptranslate {
  z-index: 1;
  position: fixed;
}

.lato {
  font-family: 'Lato', sans-serif;
}

.blue {
  color: $blueDark;
}

.img50px {
  img {
    width: 50px;
    height: auto;
  }
}
.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.mt10px {
  margin-top: 10px;
}
.mt15px {
  margin-top: 15px;
}
.mt20px {
  margin-top: 20px;
}
.mt25px {
  margin-top: 25px;
}
.mt30px {
  margin-top: 30px;
}

.ml10px {
  margin-left: 10px;
}
.ml15px {
  margin-left: 15px;
}
.ml20px {
  margin-left: 20px;
}
.ml25px {
  margin-left: 25px;
}
.ml30px {
  margin-left: 30px;
}

.mr10px {
  margin-right: 10px;
}
.mr15px {
  margin-right: 15px;
}
.mr20px {
  margin-right: 20px;
}
.mr25px {
  margin-right: 25px;
}
.mr30px {
  margin-right: 30px;
}

.pb0px {
  padding-bottom: 0 !important;
}
.pb10px {
  padding-bottom: 10px;
}
.pb15px {
  padding-bottom: 15px;
}
.pb20px {
  padding-bottom: 20px;
}
.pb25px {
  padding-bottom: 25px;
}
.pb30px {
  padding-bottom: 30px;
}

.pt0px {
  padding-top: 0 !important;
}
.pt10px {
  padding-top: 10px;
}
.pt15px {
  padding-top: 15px;
}
.pt20px {
  padding-top: 20px;
}
.pt25px {
  padding-top: 25px;
}
.pt30px {
  padding-top: 30px;
}
.pr10px {
  padding-right: 10px;
}
.pr15px {
  padding-right: 15px;
}
.pr20px {
  padding-right: 20px;
}
.pr25px {
  padding-right: 25px;
}
.pr30px {
  padding-right: 30px;
}

.p10px {
  padding: 10px;
}
.p15px {
  padding: 15px;
}
.p20px {
  padding: 20px;
}
.p25px {
  padding: 25px;
}
.p30px {
  padding: 30px;
}

.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.font16 {
  font-size: 16px;
}
.error {
  background: $red_error;
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: $red_error_color;
  p {
    color: $red_error_color;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.success {
  background: $green_success;
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: $green_success_color;
  p {
    color: $green_success_color;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.warning {
  background: $light_blue;
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  color: $blueNew;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.dark {
  color: #fff;
  background-color: #6c757d;
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.badge {
  padding: 3px 10px;
  font-size: 11px;
  border-radius: 4px;
  margin-bottom: 4px;
}
.smalTitleCaps {
  font-size: 10px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: $greyNew;
}
p {
  &.content {
    margin-top: 4px;
    font-size: 13px;
    color: black;
  }
  &.simpleContent {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.boxshadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

h1 {
  &.logo {
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: normal;
    font-size: 16px;
    color: $greyNew;
    span {
      color: $blueNew;
    }
  }
}
.linkContainer {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 20px;
  a {
    margin-bottom: 10px;
    font-size: 13px;
    color: $greyNew;
    &:hover {
      color: $blueNew;
    }
  }
}

.libelle {
  color: $greyNew;
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}
.value {
  color: #434343;
  font-size: 12px;
}

.blockDocument {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .blockImageDoc {
    max-height: 200px;
    max-width: 250px;
    text-align: center;
  }
}

.image-doc {
  cursor: zoom-in;
  max-height: 20vh;
  max-width: 15vw;
  width: 100%;
}
