$break-xsmall: 320px;
$break-small: 480px;
$break-xmedium: 785px;
$break-medium: 960px;
$break-large: 1200px;

.container {
  width: 100%;
  margin-right: 0 !important;
}

.grid {
  padding: 20px;
}

.header {
  // position: fixed;
  top: 0;
  background: #f1f1f1;
  .grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  #nav {
    z-index: 1;
    @media screen and (max-width: $break-xmedium) {
      position: fixed;
      background: white;
      width: 100%;
      border-bottom: solid 1px #e8e8e8;
    }
  }
  #topNav {
    @media screen and (max-width: $break-xmedium) {
      // &:first-child {
      margin-top: 93px;
      // }
    }
  }

  .logo {
    a {
      text-decoration: none;
    }
    img {
      cursor: pointer;
      @media screen and (max-width: $break-xmedium) {
        display: none;
      }
      height: 40px;
    }
    .hamburger {
      cursor: pointer;
      display: none;
      @media screen and (max-width: $break-xmedium) {
        display: inherit;
        margin-right: 10px;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    .download {
      a {
        text-decoration: none;
      }
      button {
        display: flex;
        align-items: center;
        padding: 13px 30px 13px 30px;
        border-radius: 25px;
        border: solid 1px #074885;
        color: #1f5288;
        background: white;
        p {
          margin-left: 10px;
        }
        &:hover {
          background: #074885;
          border: solid 1px white;
          color: white;
          svg path {
            fill: white;
          }
        }
      }
    }
    .languages {
      display: flex;
      align-items: center;
    }
  }
  .navbar-head {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #ebebeb;
    padding-left: 30px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    .hide-arrow {
      bottom: -2px;
      right: 11px;
      font-size: 30px;
      position: absolute;
      padding: 7px;
      border-radius: 30px;
      &:hover {
        cursor: pointer;
        background: #e0e0e0;
      }
    }
    &:last-child {
      padding-left: 20px;
    }
    .menu {
      display: flex;
      // margin-top: 20px;
      // align-items: center;
      // padding-right: 188px;
    }
  }
}
.card-container {
  // display: flex;
  // align-items: center;
  .card {
    display: inline-block;
    &.item {
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      width: 100%;
      // max-width: calc(100% - 100px);
      // max-width: 600px;
      // @media screen and (max-width: $break-xmedium) {
      //   width: 100%;
      // }
      min-width: 330px;
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.picture {
          display: inline-flex;
          img {
            cursor: zoom-in;
          }
        }
        &.doc {
          flex-direction: column;
          padding: 7px;
        }
        &.car {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .pictureContainer {
          // width: inherit;
          padding: 0;
          position: relative;
          .full-image {
            width: inherit;
          }
        }
        &.frevo {
          .label {
            flex: none;
            width: 30%;
            text-align: right;
            padding: 10px;
          }
          .value {
            text-align: left;
            flex: unset;
          }
          .dot {
            flex: 1;
            border-bottom: 1px dotted #d2d3d2;
            height: 3px;
          }
          .price {
            flex: none;
            text-align: right;
            padding: 4px 8px 8px 0;
          }
        }
        .name {
          text-align: right;
          flex: 1;
          padding: 10px;
          padding-top: 4px;
        }
        .value {
          text-align: left;
          flex: 1;
          padding: 10px;
          padding-top: 4px;
        }
      }
    }

    border-radius: 10px;
    background: #ffffff;

    .title {
      margin-bottom: 5px;
    }
  }
}

.fre-infos {
  cursor: pointer;
  position: absolute;
  font-size: 1rem;
  display: block;
  right: 0;
  top: -4px;
  border: solid 1px #dfdfdf;
  background: white;
  z-index: 11;
  min-width: 350px;
  padding: 10px 20px;
  text-align: justify;
  max-height: 190px;
  overflow-y: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

  &__title {
    &__price {
      font-weight: bold;
      font-style: normal;
      color: #f4a621;
    }
  }

  &__desc {
    &__title {
      font-family: 'Avenir Next LT Pro Regular', serif;
      font-size: 16px;
      font-weight: 600;
      color: rgb(85, 85, 85);
      margin-bottom: 5px;
    }
  }
}

.desc-content {
  &__title {
    font-size: 14px;
    color: rgb(123, 123, 123);
    margin-left: -10px;
  }

  &__liste {
    li b {
      font-style: normal;
      color: #f4a621;
      float: right;
    }
  }
}

.main {
  // position: relative;
  display: flex;
  // align-items: center;
  flex-direction: row;
  @media screen and (max-width: $break-xmedium) {
    flex-direction: column;
    // align-items: center;
  }
  // margin-top: 10px;

  .side-menu {
    &.drawer {
      // display: flex;
      top: 92px;
      background: white;
      height: 100%;
      //overflow: scroll;
      @media screen and (max-width: $break-xmedium) {
        display: flex;
      }
    }
    // position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    // margin-top: 23px;

    margin-right: 74px;
    width: 100%;
    max-width: 320px;
    height: 100%;
    overflow: scroll;

    @media screen and (max-width: $break-xmedium) {
      display: none;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding: 25px;
      padding: 20px 30px 20px 30px;
      margin-bottom: 3px;
      color: #4a4a4a;
      border-radius: 0 25px 25px 0;
      svg {
        display: none;
      }
      &.active {
        svg {
          display: inherit;
        }
        color: white;
        &:hover {
          background-color: #1f5288;
        }
        background-color: #1f5288;
        cursor: pointer;
        box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.15);
      }
      // &:visible {
      // }
      &:hover {
        // background-color: #a9b5c261;
        // &:hover {
        background-color: #538dcb0f;
        // }
        cursor: pointer;
        // box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.15);
      }
    }
  }
  .infinite-scroll {
    flex: 1;
    // margin-top: 190px;
    // margin-left: 348px;
    // position: fixed;
    // top: 190px;
    overflow: scroll;
    // height: -moz-calc(100% - 200px);
    // height: -webkit-calc(100% - 200px);
    height: calc(100% - 200px);
    // width: 100%;

    // height: 100%;
    @media screen and (max-width: $break-xmedium) {
      overflow: unset;
    }
    .card-container {
      // height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      @media screen and (max-width: $break-xmedium) {
        justify-content: center;
      }
    }
  }
}
.divider {
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ebebeb;

  &.newHeight {
    height: 2px;
    background-color: #c2d1df;
  }
}

.hideIfxMedium {
  @media screen and (max-width: $break-xmedium) {
    display: none;
  }
}

.noPadding {
  padding: 0 !important;
}

.p10px {
  padding: 10px;
}

.drawer-normal {
  // top: 92px;
  background: white;
}
.drawer-burger {
  // cursor: pointer;
  padding: 10px 20px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  img {
    height: 33px;
    margin-right: 25px;
  }
  // div {
  //   margin: 20px;
  //   margin-left: 0px;
  // }
}
.overlayDrawer {
  width: 100%;
}
.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}
.goog-te-combo {
  border: none;
  background: transparent;
}

.frevo-total {
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  // &__value {
  //   display: flex;
  //   justify-content: flex-end;
  //   padding-right: 15px;
  // }
}
.noLink {
  text-decoration: none;
  color: #4a4a4a;
}

.signature {
  text-align: center;
  &.specialist {
    img {
      max-width: 250px;
      width: 100%;
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

$sizes: 12;

@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i} {
      margin: $margin !important;
    }
    .ml#{$i} {
      margin-left: $margin !important;
    }
    .mr#{$i} {
      margin-right: $margin !important;
    }
    .mt#{$i} {
      margin-top: $margin !important;
    }
    .mb#{$i} {
      margin-bottom: $margin !important;
    }
    .mx#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }
    .my#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {
      padding: $padding !important;
    }
    .pl#{$i} {
      padding-left: $padding !important;
    }
    .pr#{$i} {
      padding-right: $padding !important;
    }
    .pt#{$i} {
      padding-top: $padding !important;
    }
    .pb#{$i} {
      padding-bottom: $padding !important;
    }
    .px#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}
@include padding-classes;
